// Import dependencies
import React, { useEffect, useState, useRef, lazy} from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import FlipCountdown from '@rumess/react-flip-countdown';
import UnixConvert from 'moment';
import { fetchData } from "./redux/data/dataActions";

// Import Images
import LogoVertical from './assets/img/logo-vertical.png';
import LogoHorizontal from './assets/img/logo-horizontal.png';
import TokenStack from './assets/img/token-stack.png';

import Tokenomics from './assets/img/gvv-tokenomics.jpg';

// GVV NFT
import GVVBlue from './assets/img/gvv-blue.jpg';
import GVVSilver from './assets/img/gvv-silver.jpg';
import GVVGold from './assets/img/gvv-gold.jpg';
import GVVPlatinum from './assets/img/gvv-platinum.jpg';

// GVV Perks Icon
import GVVMining from './assets/img/gvvmining.png';
import GVVStaking from './assets/img/gvvstaking.png';
import GVVAcademy from './assets/img/gvvacademy.png';
import GVVClub from './assets/img/gvvclub.png';

// Buttons
import SmartContractButton from './assets/img/smart-contract-button.png';
import SwapButton from './assets/img/swap-button.png';
import BuyButton from './assets/img/buy-button.png';
import StakeButton from './assets/img/stake-button.png';
import UnstakeButton from './assets/img/unstake-button.png';
import AboutUsButton from './assets/img/about-us.png';
import WhitepaperButton from './assets/img/whitepaper.png';
import DashboardButton from './assets/img/dashboard.png';

import { BrowserRouter as Router,Route,Routes} from 'react-router-dom';

function App() {

  // Define variables & set states
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);

  const [appLaunch, setAppLaunch] = useState(false);
  const [distributeTime, setDistributeTime] = useState();
  const getLaunchTime = () => {
    setDistributeTime(UnixConvert.unix(1685887200));
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <Router>

    <div className="container-fluid login-section">
      <div className="row">
        <div className="col-12 mb-3 text-center">
          <img className="login-logo mb-5" src={LogoVertical}></img>
          {!appLaunch ? (
            <div>
              <h5 className="text-white mb-2">DApp<br></br>Coming Soon</h5>
              <FlipCountdown
                size='small' // Options (Default: medium): large, medium, small, extra-small.
                theme='dark'
                hideYear
                hideMonth
                hideDay
                endAtZero
                onTimeUp={() => setAppLaunch(true)}
                endAt={UnixConvert.unix(1685887200)} // Date/Time
              />
            </div>
          ):(
            <div>
              <h5 className="text-white mb-2">DApp<br></br>Is Now Live!</h5>
            </div>
          )}


        </div>
        <div className="col-12 mb-2 text-center">
          <button className="btn custom-button" disabled={appLaunch ? 0 : 1} onClick={(e) => {
            if (appLaunch == false) {
              return;
            }
            window.location.replace("https://staking.gvvcrypto.io");
            // window.open("https://staking.gvvcrypto.io", "_blank");
          }}>
            <img className="" src={DashboardButton}></img>
          </button>
        </div>
        <div className="col-12 mb-2 text-center">
          <button className="btn custom-button" onClick={(e) => {    
            window.open("https://goldenvalueventure.com/home/about", "_blank");   
          }}>
            <img className="" src={AboutUsButton}></img>
          </button>
        </div>
        <div className="col-12 mb-2 text-center">
          <button className="btn custom-button" onClick={(e) => {    
            window.open("https://whitepaper.gvvcrypto.io/", "_blank");   
          }}>
            <img className="" src={WhitepaperButton}></img>
          </button>
        </div>
        <div className="col-12 mt-5 text-center">
          <h5 className="text-white mb-3">FOLLOW US</h5>
          <div className=" mb-5 social-links">
            <a href="https://twitter.com/gvvofficial" target="_blank"><i className="fa-brands fa-twitter"></i></a>
            <a href="https://t.me/GVVOfficialGroup" target="_blank"><i className="fa-brands fa-telegram"></i></a>
          </div>
        </div>

      </div>
    </div>

    <div className="container-fluid footer">
      <div className="row">
        <div className="col-12 text-center">
          <p className="copyright-text mb-0">&copy; {(new Date().getFullYear())} GOLDEN VALUE VENTURE</p>
        </div>
      </div>
    </div>
    </Router>
  );
}

export default App;
